.root {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 0;
}

.actionPanel {
  position: sticky;
  top: 56px;
  background-color: #fff;
  z-index: 2;
  padding: 20px;
  margin: -20px -20px 0;
  border-bottom: 1px solid #7f7d7d;
}

.required {
  color: red;
}

.gridParent {
  position: relative;
}

.splitGutter {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==);
  width: 2em;
  height: 2em;
  top: 10%;
  left: calc(100% + 0.75em);
  background-repeat: no-repeat;
  cursor: col-resize;
  position: absolute;
  display: none;
}

.splitWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;

  & > div:nth-child(2) {
    cursor: col-resize;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==);
    background-position: 13px 115px;
    background-repeat: no-repeat;
  }
}

.projectGrid {
  width: 100%;
  min-height: 200px !important;
  height: 200px;
  overflow-y: visible;

  .ag-row-focus {
    font-weight: bold;
  }
}

.gridDefault {
  height: 400px !important;
}

.warningCell {
  &:before {
    content: ' ! ';
    display: inline-block;
    margin-right: 3px;
    color: red;
    font-weight: bolder;
    vertical-align: bottom;
    font-size: 20px;
  }
}

.eventQuantityField {
  position: relative;

  &[data-valid='false'] {
    &:after {
      content: ' ! ';
      display: flex;
      align-items: center;
      height: 100%;
      top: 0;
      width: 2px;
      margin-right: 3px;
      color: red;
      font-weight: bolder;
      vertical-align: bottom;
      font-size: 20px;
      right: 12px;
      position: absolute;
    }

    input {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      border-color: #dc3545;
      padding-right: 25px;
    }
  }

  &[data-valid='true'] {
    input:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }
  }
}

.rightPane {
  box-shadow: 1px 1px 5px 1px #0000005e;
  background-color: #fff;
  height: calc(100% - 60px);
  padding: 20px;
  margin-top: 20px;
}

.flexButton {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.section {
  margin-bottom: 40px;
}

.timeTrackingBuckets {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;

  .timeTrackingBucket {
    width: calc(100% / 7 - 12px);
    min-width: 80px;
    margin: 0 6px 6px;
    font-size: 10px;
    line-height: 10px;

    input {
      width: 100%;
      height: 26px;
      z-index: 1;
      font-size: 10px;
      position: relative;
    }
  }

  .timeTrackingBucketProgress {
    width: 100%;
    background-color: white;
    border-radius: 0.25rem;

    &::-webkit-progress-bar {
      background-color: white;
      border-radius: 0.25rem;
    }
    &::-webkit-progress-value {
      background-color: limegreen;
      border-radius: 0.25rem;
    }
  }

  .timeTrackingBucketWarning {
    .timeTrackingBucketProgress {
      &::-webkit-progress-value {
        background-color: #f5f10b;
      }
    }
  }

  .timeTrackingBucketOvertime {
    .timeTrackingBucketProgress {
      &::-webkit-progress-value {
        background-color: indianred;
      }
    }
  }

  .timeTrackingBucketTitle {
    height: 25px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    margin-bottom: 4px;
  }
}

.completeEvent {
  margin-right: 20px;
}

.eventTable {
  margin-top: 20px;
}

.modal {
  margin-top: 85px;
}

.notesContainer {
  margin-bottom: 40px;
}

.dropDownToggle {
  fontfamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
  fontsize: '1rem';
  backgroundcolor: 'rgb(67, 73, 77)';
  borderbottomcolor: 'rgb(67, 73, 77)';
}

.requiredField:after {
  content: ' *';
  color: red;
}

.label {
  font-weight: bold;
}

.createdUpdatedInfoLabel {
  display: inline-block;
  width: 100px;
  font-weight: bold;
}

.tooltipWrapper {
  max-width: 100%;
}

.addAccountsBtn {
  padding: 3px 10px 3px 10px !important;
  position: absolute;
  margin-left: 10px;
}

.searchIcon {
  display: block;
  background-image: url('../../assets/icons/icons_search_24.png');
  width: 24px;
  height: 24px;
}
.notes-editor .ql-container {
  min-height: 150px;
  max-height: 400px;
}