.panel {
    display: flex;
    justify-content: flex-end;
    padding: 0;
}

.table {
    margin-top: 20px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
}

.search {
    margin-left: 1rem;
}

.title {
  font-size: 24px;
  float: left;
}

.btn {
  float: right;
  background-color: rgb(67, 73, 77);
  border-bottom-color: rgb(67, 73, 77);
}

.disabledRow :global(.ag-cell) {
  background-color: #b3b3b3;
  opacity: 0.7;
  cursor: not-allowed;
}

.gridLink {
  color: #459699;
  cursor: pointer;
}
