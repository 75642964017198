@import '~bootstrap/dist/css/bootstrap.css';
body {
  background-color: #F1F2F3;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #2F3237;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 5px 0px;
}

a {
  color: #459699;
  &:hover {
    color: #3f878a;
    text-decoration: none;
  }
}

.grid-action-button-save,
.grid-action-button-delete,
.grid-action-button-cancel{
  font-size: 12px;
  width: 60px;
}

.btn-primary {
  color: #ffffff!important;
  background-color: #459699!important;
  border-color: #459699!important;
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.28);
}

.btn-primary:hover {
  color: #ffffff!important;
  background-color: #3F878A!important;
  border-color: #3F878A!important;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff!important;
  background-color: #B5D5D6!important;
  border-color: #B5D5D6!important;
}

.btn-secondary {
  color: #555555!important;
  background-color: #E3E5E8!important;
  border-color: #E3E5E8!important;
  border-radius: 4px;
}

.btn-secondary:hover {
  color: #777777!important;
  background-color: #E3E5E8!important;
  border-color: #E3E5E8!important;
}

.btn-danger {
  color: #ffffff!important;
  background-color: #F05B5B!important;
  border-color: #F05B5B!important;
  border-radius: 4px;
}

.btn-danger:hover {
  color: #ffffff!important;
  background-color: #DE4646!important;
  border-color: #DE4646!important;
}

.default-grid-row-styles {
  background-color: #ffffff !important;
  cursor: pointer;

  &:hover {
    background-color: #e8f4fe !important;
  }
}

.ag-body-viewport {
  overflow-y: auto !important;
}


.tox-notifications-container {
  display: none !important;
}

.pageTitle {
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

// Override the datePicker class to set it the right priority above the .form-control
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}
