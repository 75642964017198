.root {
  margin-top: 60px;
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.form {
  padding: 1.5rem 1rem;
}

.notes {
  margin: 0 1em;
}

.projectsHeader{
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}

.parentGrid {
    min-height: 300px;
    height: 300px !important;
    margin: 16px;
    margin-top: 20px;
    width: inherit!important;
}
