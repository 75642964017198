.panel {
    display: flex;
    padding: 0px;
    margin-bottom: 16px;
}

.table {
    margin-top: 20px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
}

.createnewbutton {
    justify-content: flex-end;
    background-color: rgb(67, 73, 77);
    margin-left: auto;
}

.pagetitle {
    justify-content: flex-start;
    font-size: 24px;
}

.disabledRow :global(.ag-cell) {
    background-color: #b3b3b3;
    opacity: 0.7;
    cursor: not-allowed;
  }