.purchasedProductsGrid {
  min-height: 300px;
  height: 300px !important;
  margin: 16px;
  margin-top: 20px;
  width: inherit !important;
}

.projectsHeader{
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}

.disabledRow :global(.ag-cell) {
  background-color: #b3b3b3;
  opacity: 0.7;
}
