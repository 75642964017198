.dropDownToggle {
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
  fontSize: '1rem';
  backgroundColor: 'rgb(67, 73, 77)';
  borderBottomColor: 'rgb(67, 73, 77)';
}

.projectDescription {
  font-size: 11px;
  line-height: 11px;
}

.saveControl {
  width: 24px;
  height: 24px;
  background-image: url('../../assets/icons/save_as_24.png');
  margin-left: 25px;
  visibility: hidden;
}

.menuItemWrapper {
  display: flex;
  &:hover {
    .saveControl {
      visibility: visible;
      cursor: pointer;
    }
  }
}

.infoContainer {
  flex: 1;
}

.gridConfigOption {
  pointer-events: all !important;
  cursor: pointer;
}

.dropdown-item.disabled {
  cursor: default;
}
