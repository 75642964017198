.nav {
  width: 100%;
}

.profileDropdown {
  margin-left: auto;
}

.dropdownMenuOption {
  &:focus, &:hover, &:active {
    color: #6c757d;
    text-decoration: none;
    background-color: #e9ecef !important;
  }
}

.dropdownMenuOptionLink {
  font-size: .875em!important;
  color: #777;
}

.navigationMenuItem {
  padding: 9px 11px 8px !important;
  font-size: 16px !important;
  color: #E4EAEC !important;
}
