.virtualizedMenuPlaceholder {
  margin-top: 12px;
  color: #9a9a9a;
  text-align: center;
  list-style: none;
}
.virtualizedMenuItem {
  list-style: none;
  white-space: nowrap;
}
