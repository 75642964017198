.root {
  margin-top: 60px;
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.form {
  padding: 1.5rem 1rem;
}

