.root {
  margin-top: 60px;
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.form {
  padding: 1.5rem 1rem;
}

.notes {
  margin: 0 1em;
}

.projectsHeader{
  display: flex;
  justify-content: space-between;
  margin: 1em;
}

.subProjectGrid {
  min-height: 300px;
  height: 300px !important;
  margin: 16px;
  margin-top: 20px;
  width: inherit!important;
}

.projectsHeaderTitle {
  fontSize: '24px';
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
}

.dropDownToggle {
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
  fontSize: '1rem';
  backgroundColor: 'rgb(67, 73, 77)';
  borderBottomColor: 'rgb(67, 73, 77)';
}

.ag-status-bar {
    display: none;
}
