.multiSelect {
  z-index: 99;
  width: auto;
}

.multiSelectLabel {
  font-size: .8rem;
  margin-bottom: 0;
  font-weight: bold;
}
