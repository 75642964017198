.root {
}
.form {
  padding: 20px;
}
.title {
  font-family: "Source Sans Pro Regular", Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 30px;
  padding: 0;
  margin-bottom: 1em;
}

.container {
}

.invalid-input-color {
  color: red;
}
