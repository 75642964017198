.notes {
  margin: 0 1em;
}

.notesLabel{
  font-size: .8rem;
  margin-bottom: 0;
  font-weight: bold;
}

.notes-editor .ql-container {
  min-height: 150px;
  max-height: 400px;
}