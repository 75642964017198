.headerContainer {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 10px;
}

.colHeaderLabel {
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 0px;
}

.colValueLabel {
    font-size: 0.875rem;
}
