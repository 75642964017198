.projectForm {
  .col-form-label {
    font-size: 0.875rem;
    font-weight: bold;
  }
}
.datePicker {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  input {
    border: none;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
}

.redInputBackground {
  background-color: #ff0000;
}

.greenInputBackground {
  background-color: #00ff00;
}

.tox-editor-header{
    z-index: 0!important;
}

.actionPanelButton {
  margin-right: 20px;
}
