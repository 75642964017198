.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.panel {
    display: flex;
    justify-content: flex-end;
    padding: 0px 0px 0px 0px;
}

.table {
    margin-top: 20px;
    box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.15);
    flex-grow: 1;
}

.search {
  margin-left: 1rem;
}

.title {
  font-size: 24px;
  float: left;
}