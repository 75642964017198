@import './assets/font-awesome/css/all.min.css';
@import './variables.scss';
@import './pages/pages.scss';

html,
body {
  min-height: 100% !important;
  height: 100%;
}

#unison-teamwork {
  height: 100%;
  padding: 90px 30px 30px 30px;
}

.App {
  height: 100%;
}

.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#TeamworkApp {
  height: 100%;
  @media (min-width: 1600px) {
  }
}

.default-grid {
  min-height: 300px;
  height: calc(100% - 60px);
  width: 100%;
  margin-top: 20px;
}

.custom-grid {
  margin-top: 0;
  height: 100%;
  min-height: 150px;
}

.page-title {
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.project-description {
  font-size: 11px;
  line-height: 11px;
}

.ag-root-wrapper .ag-root .ag-row.ag-row-selected .ag-cell {
  background: #cad9e6;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}
